<template>
  <div>
    <v-dialog
      v-model="show"
      width="500"
      close-delay="1000"
      :persistent="true"
      @click:outside="onCancel"
      @keydown.stop.prevent="onKeydown"
    >
      <v-card>
        <v-card-title class="headline lighten-2">
          {{ $t(titleText) }}
        </v-card-title>
        <v-card-text class="mt-5 text-left">
          {{ $t(contentText) }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="loading"
            @click.stop="onCancel"
            class="ma-1"
            plain
            color="black"
          >
            {{ $t(cancelText) }}
          </v-btn>
          <v-btn
            :loading="loading"
            class="ma-1"
            color="error darken-1"
            text
            @click.stop="onConfirm"
          >
            {{ $t(confirmText) }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { delay } from "@/util/network";

export default {
  name: "ConfirmBox",
  data() {
    return {
      callback: function() {},
      loading: false,
      show: false,
      titleText: "",
      contentText: "",
      cancelText: "",
      confirmText: "",
      successText: "",
      promise_callback: null
    };
  },
  methods: {
    showConfirm({
      callback,
      titleText = "Confirmation",
      contentText = "Are you sure?",
      confirmText = "Confirm",
      cancelText = "Cancel"
    }) {
      this.callback = callback;
      this.titleText = titleText;
      this.contentText = contentText;
      this.confirmText = confirmText;
      this.cancelText = cancelText;
      this.show = true;
      return new Promise((resolve, reject) => {
        this.promise_callback = { resolve, reject };
      });
    },
    onConfirm() {
      this.loading = true;
      let promise = this.callback();
      if (promise) {
        this.$snackbar
          .delegateError(delay(promise, 150))
          .then(() => {
            this.promise_callback.resolve();
          })
          .catch(reason => {
            this.promise_callback.reject(reason);
          })
          .finally(() => {
            this.show = false;
            this.loading = false;
          });
      } else {
        this.promise_callback.resolve();
        this.show = false;
        this.loading = false;
        return;
      }
    },
    onCancel() {
      if (!this.show || this.loading) {
        return false;
      }

      this.show = false;
      this.promise_callback.reject("cancelled");
    },
    onKeydown(event) {
      if (event.key !== "Esc" && event.key !== "Escape") {
        return;
      }

      this.onCancel();
    }
  }
};
</script>
