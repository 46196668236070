import Vue from "vue";
import Vuex from "vuex";
import * as snackbar from "@/store/snackbar";
import { api_request } from "@/util/network";
import router from "@/router";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    initialized: false,
    config: null,
    token: null,
    identity: null,
    adminEntered: null,
    defaultLaunchpadId: null,
    loginTitle: null,
    mLoginTitle: null,

    // UI
    show_sheet: false,
    breadcrumbs: []
  },
  mutations: {
    initialized(state) {
      state.initialized = true;
    },
    auth_request(state) {
      state.status = "loading";
    },
    auth_success(state, { auth_token, identity }) {
      state.status = "success";
      state.token = auth_token;
      state.identity = identity;
    },
    auth_adminEntered(state, { separate_admin_port }) {
      state.adminEntered = separate_admin_port;
    },
    auth_error(state) {
      state.status = "error";
    },
    logout(state) {
      state.status = "";
      state.token = "";
      state.identity = "";
    },
    show_sheet(state, { show }) {
      state.show_sheet = show;
    },
    change_breadcrumbs(state, breadcrumbs) {
      state.breadcrumbs = breadcrumbs;
    },
    show_defaultLaunchpadId(state, id) {
      state.defaultLaunchpadId = id;
    },
    show_loginTitle(state, title) {
      state.loginTitle = title;
    },
    show_mLoginTitle(state, title) {
      state.mLoginTitle = title;
    }
  },
  actions: {
    async initialize({ commit }) {
      await Vue.http
        .get("api/current")
        .delegateTo(api_request)
        .then(res => {
          if (res.identity && res.auth_token) {
            commit("auth_success", {
              token: res.auth_token,
              identity: res.identity
            });
          }

          commit("initialized");
        });
    },
    async auth_adminEnter({ commit }) {
      await Vue.http
        .get("api/system_config")
        .delegateTo(api_request)
        .then(res => {
          commit("auth_adminEntered", {
            separate_admin_port: res.separate_admin_port
          });
        });
    },
    async confirmPermission({ commit }) {
      await Vue.http
        .get("api/current")
        .delegateTo(api_request)
        .then(res => {
          if (res.identity && res.auth_token) {
            commit("auth_success", {
              token: res.auth_token,
              identity: res.identity
            });
            // system user being rejected
            if (res.identity.claims && res.identity.claims.role === "system") {
              router.push("/user/home");
            }
          } else {
            commit("logout");
            router.push("/login");
          }
        });
    },
    async fetchDefaultLaunchpad({ commit }) {
      await Vue.http
        .get("api/settings/launchpad")
        .delegateTo(api_request)
        .then(res => {
          if (res && res.autorunApplication) {
            commit("show_defaultLaunchpadId", res.autorunApplication);
          }
        });
    },
    async fetchLoginTitle({ commit }) {
      await Vue.http
        .get("api/settings/login")
        .delegateTo(api_request)
        .then(res => {
          if (res && res.logoDesc) {
            commit("show_loginTitle", res.logoDesc);
          }
        });
    },
    async fetchMLoginTitle({ commit }) {
      await Vue.http
        .get("api/settings/mobile_login")
        .delegateTo(api_request)
        .then(res => {
          if (res && res.logoDesc) {
            commit("show_mLoginTitle", res.logoDesc);
          }
        });
    }
  },
  modules: { snackbar }
});
