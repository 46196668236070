<template>
  <v-navigation-drawer app temporary width="650px" right v-model="show_sheet">
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="title">
          {{
            is_readonly
              ? `${$t("source.See {type} adapter config", {
                  type: "LDAP"
                })}：${name}`
              : this.type === "create"
              ? `${$t("source.Create {type} adapter config", {
                  type: "LDAP"
                })}`
              : `${$t("source.Edit {type} adapter config", {
                  type: "LDAP"
                })}：${name}`
          }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-form class="pa-4" :model="form" v-model="valid" ref="form">
      <v-alert outlined dense type="info" class="text-left body-2 mb-8">
        LDAP身份源列表项
      </v-alert>
      <v-row>
        <v-col cols="3" class="mt-2">
          <v-subheader> 身份源ID</v-subheader>
        </v-col>
        <v-col cols="9">
          <v-textarea
            v-model="external_id"
            :disabled="type !== 'create'"
            :auto-grow="true"
            rows="1"
            persistent-hint
            :hint="$t('ID Info')"
          >
          </v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <v-subheader><span class="red--text">*</span> 显示名称</v-subheader>
        </v-col>
        <v-col cols="9">
          <v-textarea
            :auto-grow="true"
            rows="1"
            v-model="name"
            :readonly="is_readonly"
            :disabled="is_readonly"
            required
            :rules="rules.notNullRules"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <v-subheader><span class="red--text">*</span> 服务器地址</v-subheader>
        </v-col>
        <v-col cols="9">
          <v-textarea
            :rules="rules.notNullRules"
            persistent-hint
            :auto-grow="true"
            rows="1"
            :disabled="is_readonly"
            :readonly="is_readonly"
            :hint="is_readonly ? '' : 'LDAP服务器IP，如： 127.0.0.1'"
            v-model="form.host"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <v-subheader><span class="red--text">*</span> 端口号</v-subheader>
        </v-col>
        <v-col cols="9" prop="port">
          <v-textarea
            :rules="is_readonly ? [] : rules.isPort"
            persistent-hint
            :auto-grow="true"
            rows="1"
            :disabled="is_readonly"
            :readonly="is_readonly"
            :hint="is_readonly ? '' : 'LDAP服务器端口，如：389'"
            v-model="form.port"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <v-subheader><span class="red--text">*</span> 用户名</v-subheader>
        </v-col>
        <v-col cols="9">
          <v-textarea
            :auto-grow="true"
            rows="1"
            :rules="rules.notNullRules"
            persistent-hint
            :disabled="is_readonly"
            :readonly="is_readonly"
            :hint="
              is_readonly
                ? ''
                : '需要有以上填写的Base的管理权限，如：cn=Manager,dc=userName,dc=com'
            "
            v-model="form.username"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <v-subheader><span class="red--text">*</span> 密码</v-subheader>
        </v-col>
        <v-col cols="9">
          <v-textarea
            :auto-grow="true"
            rows="1"
            :rules="rules.notNullRules"
            persistent-hint
            :disabled="is_readonly"
            :readonly="is_readonly"
            :hint="is_readonly ? '' : 'LDAP账户对应的密码'"
            v-model="form.password"
            type="password"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <v-subheader><span class="red--text">*</span> BaseDN</v-subheader>
        </v-col>
        <v-col cols="9">
          <v-textarea
            :auto-grow="true"
            rows="1"
            :rules="rules.notNullRules"
            persistent-hint
            :disabled="is_readonly"
            :readonly="is_readonly"
            :hint="
              is_readonly
                ? ''
                : 'LDAP中的节点，会到该节点下认证账户，如： dc=idsmanager,dc=com'
            "
            v-model="form.base_dn"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <v-subheader><span class="red--text">*</span> 过滤条件</v-subheader>
        </v-col>
        <v-col cols="9">
          <v-textarea
            :auto-grow="true"
            rows="1"
            :rules="rules.notNullRules"
            persistent-hint
            :disabled="is_readonly"
            :readonly="is_readonly"
            :hint="
              is_readonly
                ? ''
                : 'LDAP中匹配本系统用户名的筛选条件，如：(sAMAccountName=%s)，举例中的 %s 为本系统用户名参数，是固定值。'
            "
            v-model="form.search_filter"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <v-subheader><span class="red--text">*</span> 解码方式</v-subheader>
        </v-col>
        <v-col cols="9">
          <v-textarea
            :auto-grow="true"
            rows="1"
            :rules="rules.notNullRules"
            :readonly="is_readonly"
            persistent-hint
            :disabled="is_readonly ? 'disabled' : false"
            :hint="is_readonly ? '' : 'LDAP返回信息的解码方式'"
            v-model="form.encoding"
          ></v-textarea>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="d-flex" cols="3">
          <v-subheader><span class="red--text">*</span> 启用SSL</v-subheader>
        </v-col>
        <v-col cols="9">
          <!--          <v-select-->
          <!--            persistent-hint-->
          <!--            :hint="is_readonly-->
          <!--                ? ''-->
          <!--                :'LDAP密码加密方式, 若未加密则选择 NONE。'"-->
          <!--            solo-->
          <!--            :disabled="is_readonly ? 'readonly' : false"-->
          <!--            label="None"-->
          <!--            v-model="form.enable_ssl"-->
          <!--          ></v-select>-->
          <v-switch
            :disabled="is_readonly"
            :readonly="is_readonly"
            inset
            hide-details="true"
            dense
            v-model="form.enable_ssl"
          ></v-switch>
        </v-col>
      </v-row>
      <v-row v-if="!is_readonly">
        <!-- <v-col class="d-flex" cols="3"></v-col> -->
        <v-col cols="12" class="text-center">
          <v-btn
            elevation="4"
            medium
            color="primary"
            @click="type === 'create' ? create(form) : save(form)"
            >{{ type === "create" ? "创建" : "提交" }}</v-btn
          >
          <!-- <v-btn class="ml-6" elevation="4" medium color="gary" @click="reset()"
            >重置</v-btn
          > -->
        </v-col>
      </v-row>
    </v-form>
  </v-navigation-drawer>
</template>

<script>
import { modify_request, create_request } from "@/util/network";
import bus from "@/util/eventBus";

export default {
  name: "LdapEditor",
  props: ["source_id", "is_readonly", "type", "allDatas"],
  data() {
    let isTrueUrl = value => {
      let reg = /(https?):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/;
      return reg.test(value);
    };
    let isTruePort = value => {
      if (value >= 0 && value <= 65535) {
        return true;
      } else {
        return false;
      }
    };

    return {
      valid: true,
      name: "",
      external_id: "ldap",
      form: {},
      rules: {
        notNullRules: [v => !!v || "此项为必填项"],
        isurl: [v => isTrueUrl(v) || "请输入正确的URL"],
        isPort: [v => isTruePort(v) || "请输入正确的端口号"]
      }
    };
  },
  created() {
    if (this.type !== "create") {
      this.fetchData();
    }
  },
  methods: {
    save() {
      if (this.$refs.form.validate()) {
        let payload = {
          new_name: this.name,
          new_options: this.form
        };
        const promise = modify_request(this.$route.params.id, payload)
          .then(() => {
            this.$store.commit("show_sheet", { show: false });
            bus.$emit("refresh");
            return "更新成功";
          })
          .catch(({ code, message }) => {
            throw `更新失败：${this.$t("api." + code)}, 额外信息: ${"api." +
              JSON.stringify(message)}`;
          });
        this.$snackbar.delegate(promise);
      }
    },
    create() {
      if (this.$refs.form.validate()) {
        this.form["enable_ssl"] = this.form["enable_ssl"] ? true : false;
        let payload = {
          name: this.name,
          external_id: this.external_id,
          type: "LDAP",
          options: this.form //严格对应后端的options
        };
        create_request(payload)
          .then(() => {
            this.$store.commit("show_sheet", { show: false });
            this.$router.push({ path: `/source/list` });
            return "创建成功";
          })
          .catch(({ code, message }) => {
            throw `创建失败：${this.$t("api." + code)}, 额外信息: ${"api." +
              JSON.stringify(message)}`;
          })
          .delegateTo(this.$snackbar.delegate);
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    fetchData() {
      let { name, options, external_id } = this.allDatas;
      this.external_id = external_id;
      this.name = name;
      if (options) {
        this.form = options;
      }
    }
  },
  computed: {
    show_sheet: {
      get() {
        return this.$store.state.show_sheet;
      },
      set(value) {
        this.$store.commit("show_sheet", { show: value });
        if (this.type === "create") {
          // this.reset();
          this.form.port = 389;
          this.form.encoding = "ascii";
          this.external_id = "ldap";
        }
      }
    }
  },
  watch: {
    allDatas() {
      this.fetchData();
    }
  }
};
</script>
